.App {
  text-align: center;
}

.App-logo {
  pointer-events: none;
  width: 100%; 
  height: auto;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: skew 5s infinite;
    transform: skew(5deg);
    animation-direction: alternate;
  }
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes skew {
  0% {
    transform: skew(5deg, 5deg);
  }
  100% {
    transform: skew(-5deg, -5deg);
  }
}

.temp-logo_wrapper {
  display: flex;
  padding: 40px;
}